import { combineReducers } from 'redux';
import { slice as notifications } from '../_BLL/notifications/slice';
import { slice as auth } from '../../pages/AuthPage/_BLL/slice';
import { slice as mainSearch } from '../../pages/MainPage/api/mainSearch/slice';
import { slice as booleanSearch } from '../../pages/MainPage/api/booleanSearch/slice';
import { slice as savedQueries } from 'src/pages/MainPage/api/savedQueries/slice';
import { slice as mainSearchResults } from '../../pages/MainSearchResultsPage/api/searchResults/slice';
import { slice as mainSearchExcel } from '../../pages/MainSearchResultsPage/api/excelExport/slice';
import { slice as customizeColumns } from '../../pages/MainSearchResultsPage/api/customizeColumns/slice';
import { slice as savedColumns } from '../../pages/MainSearchResultsPage/api/collection/slice';
import { slice as database } from '../../pages/DatabasePage/api/database/slice';
import { slice as databaseNew } from '../../pages/DatabaseNewPage/api/database/slice';
import { slice as chatGPT } from '../../pages/MainPage/api/chatGPT/slice';
// Portfolio Analyzer
import { slice as portfolioAnalyzer } from 'src/pages/portfolio_analyzer_pages/api/portfolio_analyzer/slice';
import { slice as portfolioTable } from 'src/pages/portfolio_analyzer_pages/api/portfolio_table/slice';
import { slice as portfolioConfig } from 'src/pages/portfolio_analyzer_pages/api/portfolio_config/slice';
// Funds
import { slice as funds } from 'src/pages/funds_pages/api/funds/slice';
import { slice as fundsTable } from 'src/pages/funds_pages/api/table/slice';
import { slice as fundsConfig } from 'src/pages/funds_pages/api/config/slice';

const mainSearchResultsReducers = {
	mainSearchResults: mainSearchResults.reducer,
	customizeColumns: customizeColumns.reducer,
	savedColumns: savedColumns.reducer,
	mainSearchExcel: mainSearchExcel.reducer,
};

const portfolioAnalyzerReducers = {
	portfolioAnalyzer: portfolioAnalyzer.reducer,
	portfolioTable: portfolioTable.reducer,
	portfolioConfig: portfolioConfig.reducer,
};

const fundsReducers = {
	funds: funds.reducer,
	fundsTable: fundsTable.reducer,
	fundsConfig: fundsConfig.reducer,
};

export const rootReducer = combineReducers({
	...mainSearchResultsReducers,
	...portfolioAnalyzerReducers,
	...fundsReducers,

	auth: auth.reducer,

	notifications: notifications.reducer,
	mainSearch: mainSearch.reducer,
	booleanSearch: booleanSearch.reducer,
	savedQueries: savedQueries.reducer,
	database: database.reducer,
	databaseNew: databaseNew.reducer,
	chatGPT: chatGPT.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
