import { DatabaseData } from './types';
import { RequestStatus } from 'src/shared/api/types';
import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../../../../app/redux/createAction';
import { apiRequest } from 'src/shared/api/api';

const NAME = 'database_new';

// * Async thunks
export const getDatabase = createAppAsyncThunk(`${NAME}/getDatabase`, async (arg: { url: string; params: { id: string } }, thunkAPI) => {
	const { dispatch } = thunkAPI;
	const { url, params } = arg;

	const res = await apiRequest.getRequest<DatabaseData>({
		url,
		params,
		thunkAPI,
	});

	if (url !== 'company') {
		if (url === 'country' && params.id.length > 3) {
			return res;
		} else {
			dispatch(
				actionsDatabaseNew.getStats({
					url,
					params,
				}),
			);
		}
	}

	return res;
});

export const getStats = createAppAsyncThunk(`${NAME}/getStats`, async (arg: { url: string; params: { id: string } }, thunkAPI) => {
	const { url, params } = arg;

	return await apiRequest.getRequest<{ [p: string]: number }>({
		url: `${url}Stats`,
		params,
		thunkAPI,
	});
});

// * Reducer
export interface State {
	data: DatabaseData | null;
	stats: { [key: string]: number } | null; // Country or Industry only
	pdfImages: Record<string, string> | null;
	imagesLoading: number;
	menuOption: number;
	status: RequestStatus;
}

export const initialState: State = {
	data: null,
	stats: null,
	pdfImages: null,
	imagesLoading: 0,
	menuOption: 1,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		databaseClear: state => {
			state.data = null;
			state.stats = null;
		},
		menuSelect: (state, action: { payload: { menuOption: number } }) => {
			state.menuOption = action.payload.menuOption;
		},
		imageLoadStart: state => {
			state.imagesLoading = state.imagesLoading + 1;
		},
		imageSave: (state, action: { payload: { title: string; image: string } }) => {
			const { title, image } = action.payload;

			state.pdfImages = {
				...state.pdfImages,
				[title]: image,
			};
			state.imagesLoading = state.imagesLoading - 1;
		},
	},
	extraReducers: builder => {
		builder.addCase(getDatabase.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDatabase.fulfilled, (state, action) => {
			state.data = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getDatabase.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getStats.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getStats.fulfilled, (state, action) => {
			state.stats = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getStats.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsDatabaseNew = {
	...slice.actions,
	getDatabase,
	getStats,
};
