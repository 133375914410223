import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ButtonSquared } from 'src/shared/ui/_buttons/ButtonSquared';
import { PasswordField } from 'src/shared/ui/_form_fields/PasswordField';
import { Card } from 'src/shared/ui/_layout/Card';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../app/redux/createAction';
import s from './ResetPasswordPage.module.scss';
import { actionsAuth } from './_BLL/slice';
import { ResetPasswordREQ } from './_BLL/types';

export const ResetPasswordPage: FC = () => {
	// * router
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	// * local storage
	const tempUserName = localStorage.getItem('temp_user_name');

	// * Selectors
	const resetStatus = useAppSelector(state => state.auth.resetStatus);

	// * Actions
	const dispatch = useAppDispatch();
	const { clearResetStatus, resetPassword } = actionsAuth;

	// * Initialize
	useEffect(() => {
		dispatch(clearResetStatus());
	}, []);

	// * Form
	const MAX_LENGTH = 6;
	const TWO_UPPERCASE_RGX = /[A-Z].*[A-Z]/;
	const TWO_DIGITS_RGX = /(\D*\d){2}/;

	const schema = Yup.object().shape({
		password: Yup.string()
			.required('New password is required')
			.min(MAX_LENGTH, 'Password must be at least 6 characters long')
			.matches(TWO_UPPERCASE_RGX, 'Password must include at least 2 uppercase letters')
			.matches(TWO_DIGITS_RGX, 'Password must include at least 2 digits'),
		passwordRepeat: Yup.string().required('Repeat the new password'),
	});

	const formMethods = useForm({
		defaultValues: {
			key: searchParams.get('key') as string,
			user_name: tempUserName as string,
			password: '',
			passwordRepeat: '',
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, setError, watch } = formMethods;

	const onSubmit = (values: ResetPasswordREQ['params'] & { passwordRepeat: string }) => {
		if (values.password !== values.passwordRepeat) {
			setError('passwordRepeat', { message: 'passwords should be identical' });
		} else {
			dispatch(
				resetPassword({
					params: {
						...values,
					},
				}),
			)
				.unwrap()
				.then(() => localStorage.setItem('temp_user_name', ''));
		}
	};

	const password = watch('password');

	const lengthFit = password.length >= MAX_LENGTH;
	const hasUpperCase = !!password.match(TWO_UPPERCASE_RGX);
	const hasDigits = !!password.match(TWO_DIGITS_RGX);

	// * Render
	return (
		<div className={s.container}>
			<Card className={s.card}>
				{resetStatus ? (
					<p>
						{resetStatus}{' '}
						<b
							className={s.link}
							onClick={() => navigate('/')}
						>
							Go back to login page
						</b>
					</p>
				) : (
					<form
						className={s.form}
						onSubmit={handleSubmit(onSubmit)}
					>
						<FormProvider {...formMethods}>
							<b>Create new password</b>

							<div>
								<div>The password must fulfill the following:</div>

								<ul className={s.list}>
									<li>
										<div className={cn(s.caption, lengthFit && s.caption_done)}>At least 6 characters long</div>
									</li>

									<li>
										<div className={cn(s.caption, hasUpperCase && s.caption_done)}>At least 2 uppercase letters</div>
									</li>

									<li>
										<div className={cn(s.caption, hasDigits && s.caption_done)}>At least 2 digits</div>
									</li>
								</ul>
							</div>

							<PasswordField
								name="password"
								label="New password"
							/>

							<PasswordField
								name="passwordRepeat"
								label="Repeat new password"
							/>

							<ButtonSquared type="submit">reset password</ButtonSquared>
						</FormProvider>
					</form>
				)}
			</Card>
		</div>
	);
};
