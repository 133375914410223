import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonSquared } from 'src/shared/ui/_buttons/ButtonSquared';
import { StringField } from 'src/shared/ui/_form_fields/StringField';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../app/redux/createAction';
import { actionsAuth } from '../_BLL/slice';
import { ForgotPasswordREQ } from '../_BLL/types';
import s from './ResetPasswordForm.module.scss';

interface Props {
	userName: string;
}

export const ResetPasswordForm: FC<Props> = props => {
	const { userName } = props;

	// * Selectors
	const resetStatus = useAppSelector(state => state.auth.resetStatus);
	const status = useAppSelector(state => state.auth.status);

	// * Actions
	const dispatch = useAppDispatch();
	const { forgotPassword } = actionsAuth;

	// * Form
	const schema = Yup.object().shape({
		user_name: Yup.string().required(),
	});

	const formMethods = useForm({
		defaultValues: {
			user_name: '',
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, setValue } = formMethods;

	useEffect(() => {
		setValue('user_name', userName);
	}, [userName]);

	const onSubmit = (values: ForgotPasswordREQ['params']) => {
		dispatch(
			forgotPassword({
				params: {
					...values,
				},
			}),
		);
	};

	// * Render
	return (
		<>
			{resetStatus ? (
				<div className={s.message}>
					<h3 className={s.message__title}>Team Denominator</h3>

					<p>{resetStatus}</p>
					{resetStatus.startsWith('No email') && (
						<p>
							Please contact us at <a href="mailto:support@denominator.com">support@denominator.com</a>
						</p>
					)}
				</div>
			) : (
				<form
					className={s.form}
					onSubmit={handleSubmit(onSubmit)}
				>
					<FormProvider {...formMethods}>
						<h2>Forgot password</h2>

						<StringField
							label={'user name'}
							name={'user_name'}
						/>

						<ButtonSquared
							type="submit"
							status={status}
						>
							reset password
						</ButtonSquared>
					</FormProvider>
				</form>
			)}
		</>
	);
};
